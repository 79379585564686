/**
 * @Author : yeondo
 * @Date : 2020.09.14
 * @Title : HeaderNavbar (admin menubar)
 *
 * @date 2021.07.20
 * @description header 변경
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import * as s from './HeaderStyled';

const HeaderPresenter = ({ pathname, logOut }) => {
  return (
    <s.Header>
      <s.Container>
        <s.Home to="/">AIGENN 관리자</s.Home>

        <s.Menu>
          <s.MenuLink
            to="/myzzym/examine"
            $current={pathname.includes('/myzzym') || pathname === '/'}
          >
            마이짐
          </s.MenuLink>
          {/* <s.MenuLink
            to="/maiclass/contact"
            $current={pathname.includes('/maiclass')}
          >
            마이클래스
          </s.MenuLink> */}

          <s.MenuItem onClick={() => logOut()}>로그아웃</s.MenuItem>
        </s.Menu>
      </s.Container>
    </s.Header>
  );
};

export default withRouter(HeaderPresenter);
