import Axios from 'axios';
import ENV from 'config/config';

/*
 *
 * React Components에서 Backend 호출 시 아래 해당 하는 메서드를 호출한다.
 * Backend API 호출하므로 Java의 Controller URL과 매핑이 되야한다.
 *
 */
export const header = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

export const api = Axios.create({
  baseURL: `${ENV.MYZZYM_API_SERVER}/myzzym`,
});

export const maiclass_api = Axios.create({
  baseURL: `${ENV.MAICLASS_API_SERVER}/maiclass/api`,
});
