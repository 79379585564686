/* 알림 카테고리 */
const CATEGORY = {
  BOOKING: 'BOOKING',
  MESSAGE: 'MESSAGE',
  ACTIVITY: 'ACTIVITY',
  MARKETING: 'MARKETING',
};

const TYPE = {
  NOTICE: 'NOTICE',
  EVENT: 'EVENT',
  PERSONAL: 'PERSONAL',
};

module.exports = { CATEGORY, TYPE };
