const ENV = {
  MYZZYM_API_SERVER: 'https://api.myzzym.com',
  MAICLASS_API_SERVER: 'https://api.maiclass.com',
  CDN_IMAGE_PATH: 'https://cdn.myzzym.com/myzzym/images',

  SENTRY: {
    DSN: 'https://16a6016b97254eeb8323476fdcb625c5@o4504808614264832.ingest.sentry.io/4504829937319936',
    ENABLE_ERROR_HANDLER: true,
    ENABLE_REQUEST_TRACING: true,
    TRACING_SAMPLING_RATE: 0.2,
  },
};

export default ENV;
