/**
 * @Author : yeondo
 * @Date : 2020.09.14
 * @Title : HeaderNavbar (admin menubar)
 *
 * @date 2021.07.20
 * @description header container presenter
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth } from 'context/auth';
import HeaderPresenter from './HeaderPresenter';
import { api } from 'api/api-base';
import { userApi } from 'api/api-user';

const HeaderContainer = ({ history, location: { pathname } }) => {
  const { authTokens, setAuthTokens, setIsFinishAuth } = useAuth();
  const logOut = async () => {
    /* log out logics... */
    try {
      await userApi.logoutUser();
      setIsFinishAuth(false);
      delete api.defaults.headers.common[`Authorization`];
      setAuthTokens();
      history.push('/login');
    } catch {
      alert('문제가 발생하였습니다');
    }
  };
  return (
    <>{authTokens && <HeaderPresenter pathname={pathname} logOut={logOut} />}</>
  );
};

export default withRouter(HeaderContainer);
