const ENV = {
  MYZZYM_API_SERVER: 'http://localhost:8080',
  MAICLASS_API_SERVER: 'http://localhost:8081',
  CDN_IMAGE_PATH: 'https://cdn.myzzym.com/myzzym_dev/images',

  SENTRY: {
    DSN: 'https://207b69d312a84be4a6f879569e956d0f@o4504808870772736.ingest.sentry.io/4504829934436352',
    ENABLE_ERROR_HANDLER: false,
    ENABLE_REQUEST_TRACING: false,
    TRACING_SAMPLING_RATE: 1.0,
  },
};

export default ENV;
