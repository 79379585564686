import styled from 'styled-components';

export const Aigeen = {
  AigeenColor: '#010953',
};

export const MyzzymTheme = {
  MyzzymColor: '#1bc1bb',
  MyzzymNoTrans: '#f2fbfc',
  MyzzymFormColor: '#00848A',
  MyzzymOpacity: 'rgba(27,193,187,0.03)',
  LightMyzzymColor: 'rgba(27,193,187,0.05)',
  LightGray: '#fafafa',
  LightGrayBG: 'rgb(250,250,250)',
  Gray: '#8d8d8d',
  GrayBorder: '#ededed',
  DeepGray: '#757575',
  Black: '#303030',
  PlaceHolderColor: '#AFAFAF',
  Blue: 'rgb(36, 167, 255)',
  White: '#FFFFFF',
  Green: '#49C219',
  Red: '#ff4949',
  Orange: '#FDAC2D',
};

export const MaiclassTheme = {};

export const Checkbox = styled.input`
  width: 15px;
  height: 15px;
  border: 1px solid ${MyzzymTheme.GrayBorder};
  border-radius: 3px;
  background-color: ${MyzzymTheme.LightGray};

  &:checked {
    border: 1px solid ${MyzzymTheme.MyzzymColor};
    background-color: ${MyzzymTheme.MyzzymColor};
  }
`;

export const Padding = '20px';

export const Border = `1px solid ${MyzzymTheme.GrayBorder}`;

export const Inner = `
  width: 80%;
`;

export const Flex = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexStart = `
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FlexEnd = `
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FlexBetween = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
