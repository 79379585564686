import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as styles from 'util/style/Styles';

export const Sidebar = styled.nav`
  width: 100%;
  height: 3.125rem;
  background-color: ${styles.MyzzymTheme.White};
  border-bottom: 0.75px solid ${styles.MyzzymTheme.GrayBorder};
  color: ${styles.MyzzymTheme.Gray};
  position: fixed;
  top: 58px;
  z-index: 990;
  ${styles.Flex};
`;

export const List = styled.ul`
  width: 80%;
  height: 100%;
  ${styles.Flex};
  justify-content: flex-end;
`;

export const SubBar = styled.div`
  background-color: ${styles.MyzzymTheme.LightGray};
  ${styles.Flex};
  height: 40px;
`;

export const SubBarItem = styled.li`
  width: 25%;
  ${styles.Flex};
`;

export const SLink = styled(Link)`
  width: 25%;
  height: 100%;
  color: ${(props) =>
    props.$current ? styles.MyzzymTheme.MyzzymColor : styles.MyzzymTheme.Gray};
  border-bottom: 1px solid
    ${(props) =>
      props.$current ? styles.MyzzymTheme.MyzzymColor : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;

  &:hover {
    color: ${(props) =>
      props.$current
        ? styles.MyzzymTheme.MyzzymColor
        : styles.MyzzymTheme.Gray};
    background-color: ${styles.MyzzymTheme.LightGray};
  }
`;

export const SubSLink = styled(Link)`
  ${styles.Flex};
  font-size: 0.875rem;
  color: ${(props) =>
    props.$current ? styles.MyzzymTheme.MyzzymColor : styles.MyzzymTheme.Gray};
  font-weight: ${(props) => (props.$current ? 500 : 'normal')};
  padding: 0 ${styles.Padding};
  &:hover {
    color: ${styles.MyzzymTheme.MyzzymColor};
    text-decoration: none;
  }
`;
