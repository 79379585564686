import ENV_LOCAL from './localConfig';
import ENV_DEVELOPMENT from './developmentConfig';
import ENV_PRODUCTION from './productionConfig';

const ENV =
  process.env.REACT_APP_CONFIG === 'PRODUCTION'
    ? ENV_PRODUCTION
    : process.env.REACT_APP_CONFIG === 'DEVELOPMENT'
    ? ENV_DEVELOPMENT
    : ENV_LOCAL;

export default ENV;
