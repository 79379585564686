//*********************************
// 로그인이 필요한 페이지만 라우팅 시킨다
// @author: kimpro
// @datae: 2020.06.04
//*********************************
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'context/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authTokens, isFinishAuth } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {isFinishAuth && (
            <>
              {authTokens ? (
                <Component {...props} />
              ) : (
                <>
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { referer: props.location },
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    />
  );
};
export default PrivateRoute;
