/**
 * @author suyeon-jung
 * @date 2021.06.21
 * @description 알림 메뉴 추가
 *
 * @author ydoni
 * @date 2021.07.20
 * @description layout 수정
 */

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as s from './SubHeaderStyled';

export default withRouter(({ location: { pathname } }) => {
  // 알림 서브 메뉴 가시성 여부
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <s.Sidebar>
        <s.List>
          {/* if myzzym menu is selected ... show myzzym side menu */}
          {(pathname.includes('/myzzym') || pathname === '/') && (
            <>
              <s.SLink
                to="/myzzym/examine"
                $current={
                  !isVisible &&
                  (pathname.includes('/myzzym/examine') || pathname === '/')
                }
                onClick={() => setIsVisible(false)}
              >
                검수
              </s.SLink>

              <s.SLink
                to="/myzzym/booking"
                $current={!isVisible && pathname.includes('/myzzym/booking')}
                onClick={() => setIsVisible(false)}
              >
                예약
              </s.SLink>

              <s.SLink
                to="/myzzym/earning"
                $current={!isVisible && pathname.includes('/myzzym/earning')}
                onClick={() => setIsVisible(false)}
              >
                결산
              </s.SLink>
              <s.SLink
                to="/myzzym/contacts"
                $current={!isVisible && pathname.includes('/myzzym/contacts')}
                onClick={() => setIsVisible(false)}
              >
                문의
              </s.SLink>

              <s.SLink
                to="/myzzym/notice"
                onClick={() => setIsVisible(!isVisible)}
                $current={
                  isVisible ||
                  pathname.includes('/myzzym/notice') ||
                  pathname.includes('/myzzym/event') ||
                  pathname.includes('/myzzym/personal')
                }
              >
                알림
              </s.SLink>
            </>
          )}

          {pathname.includes('/maiclass') && (
            <>
              <s.SLink
                to="/maiclass/contact"
                $current={pathname.includes('/maiclass/contact')}
              >
                문의 내역
              </s.SLink>
            </>
          )}
          {/* add more platforms ... */}
        </s.List>
      </s.Sidebar>
      {isVisible ||
      pathname.includes('/myzzym/notice') ||
      pathname.includes('/myzzym/event') ||
      pathname.includes('/myzzym/personal') ? (
        <s.SubBar>
          <s.List>
            <s.SubBarItem>
              <s.SubSLink
                to="/myzzym/notice"
                $current={pathname.includes('/myzzym/notice')}
              >
                공지사항
              </s.SubSLink>
              <s.SubSLink
                to="/myzzym/event"
                $current={pathname.includes('/myzzym/event')}
              >
                이벤트
              </s.SubSLink>
              <s.SubSLink
                to="/myzzym/personal"
                $current={pathname.includes('/myzzym/personal')}
              >
                퍼스널
              </s.SubSLink>
            </s.SubBarItem>
          </s.List>
        </s.SubBar>
      ) : null}
    </>
  );
});
