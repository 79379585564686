/**
 * @author suyeon-jung
 * @date 2021.06.22
 * @description 공통 div container
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  /* height: ${window.innerHeight - 90}px; */
  /* ${(props) => !props.login && `overflow-y: scroll;`} */
  margin: 0 auto;
`;

const DefaultContainer = ({ children, login }) => {
  return <Container login>{children}</Container>;
};

export default DefaultContainer;
