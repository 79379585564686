import styled from 'styled-components';
import { MyzzymTheme } from 'util/style/Styles';

export const Form = styled.form``;

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  padding: 8px;
`;

export const LogoBox = styled.div`
  width: 220px;
  margin: 0px auto 110px;
`;

export const LogoImage = styled.img`
  width: 100%;
`;

export const CenterBox = styled.div`
  width: 385px;
  margin: 0 auto;
  padding-top: 120px;
  position: relative;
`;

export const LoginTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
`;

export const InputBox = styled.li`
  width: 100%;
  height: 52px;
  background-color: ${MyzzymTheme.White};
  border-radius: 10px;
  border: 1px solid ${MyzzymTheme.GrayBorder};
  margin-bottom: 10px;
  text-align: center;
`;

export const Input = styled.input`
  width: 95%;
  height: 90%;
  border: none;
  border-radius: 10px;
  margin: 2px auto;
`;

export const InputLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2%;
`;

export const LoginButton = styled.button`
  width: 100%;
  height: 45px;
  background-color: #010953;
  color: ${MyzzymTheme.White};
  font-weight: 500;
  border-radius: 10px;
  margin-top: 10px;
`;

export const SearchSignBox = styled.ul`
  width: max-content;
  margin: 15px auto;
`;

export const SearchSignList = styled.li`
  float: left;
`;

export const BottomSubTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${MyzzymTheme.Gray};
  font-weight: 400;
`;
