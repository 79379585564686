const LoginResultCode = {
  MYZZYM_EXIST: "마이짐 계정이 존재합니다.",
  KAKAO_EXIST: "카카오 계정이 존재합니다.",
  NAVER_EXIST: "네이버 계정이 존재합니다.",
  APPLE_EXIST: "애플 계정이 존재합니다.",
  EMAIL_FAIL: "올바르지 않은 계정입니다.",
  PASSWORD_FAIL: "올바르지 않은 패스워드입니다.",
  MULTIPLE_EXIST: "문제가 발생하였습니다. 계속되면 문의해주세요.",
};

export default LoginResultCode;
