import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as styles from 'util/style/Styles';

// header 전체
export const Header = styled.header`
  width: 100%;
  height: 3.625rem;
  background-color: ${styles.MyzzymTheme.LightGray};
  border-bottom: 0.75px solid ${styles.MyzzymTheme.GrayBorder};
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// header 중간
export const Container = styled.div`
  width: 80%;
  ${styles.FlexBetween};
`;

export const Home = styled(Link)`
  color: ${styles.Aigeen.AigeenColor};
  font-weight: 600;
`;

export const Menu = styled.ul`
  display: flex;
`;

export const MenuCss = `
  font-size: 0.938rem;
  padding: 0 ${styles.Padding};
`;

export const MenuLink = styled(Link)`
  display: inline-block;
  ${MenuCss};
  font-weight: ${(props) => (props.current ? 600 : 'normal')};
  color: ${(props) =>
    props.current ? styles.MyzzymTheme.Black : styles.MyzzymTheme.Gray};
`;

export const MenuItem = styled.li`
  cursor: pointer;
  padding-left: ${styles.Padding};
  font-size: 0.938rem;
  color: ${styles.MyzzymTheme.Gray};
`;
