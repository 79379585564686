import { api } from './api-base';

export const userApi = {
  /**
   * User 조회 (User + Earning데이터가 포함되어있다)
   * 1. userTel
   * 2. userFirstName
   * 3. userLastName
   */
  searchUsers: (params) =>
    api.get(`/users`, {
      params: params,
    }),

  /**
   * @PostMapping("/users/login") - 사용자 로그인
   */
  loginUser: (formData) =>
    api.post('/users/login', formData, {
      withCredentials: true,
    }),

  /**
   * @PostMapping("/users/refresh") - refreshToken을 통해 accessToken 발급
   * refreshToken없는 경우는 아무것도 안함
   * withCredentials -> cookie 주고받기 위함
   */
  refreshUser: () =>
    api.post(`/users/refresh`, null, {
      withCredentials: true,
    }),

  /**
   * @PostMapping("/users/logout") - 로그아웃 -> refreshToken 삭제
   */
  logoutUser: () =>
    api.post(`/users/logout`, null, {
      withCredentials: true,
    }),
};
